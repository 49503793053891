.container {
  --thickness: 4px;
  --size: 1rem;
  /* border: var(--thickness) solid ; */
  border: var(--thickness) solid #b2bcb6;
  border-radius: 50%;
  border-top: var(--thickness) solid #00000000;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  aspect-ratio: 1/1;
  width: var(--size);
}

.sm {
  --thickness: 2px;
  --size: 1rem;
}

.md {
  --thickness: 4px;
  --size: 2rem;
}

.lg {
  --thickness: 4px;
  --size: 3rem;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
