/*********************** TMC  ********************/

/* Font Import */
@import url('https://fonts.cdnfonts.com/css/graphik-trial');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Livvic:wght@100;200;300;400;500;600;700;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

/* Variables */
:root {
 --primary-color: #8B6C23;
 --secondary-color: #012F65;
 --text-black: #23211D;
 --main-bg: #fff;
 font-family: 'Graphik Trial', sans-serif;
 --generic-font: 'Merriweather', serif;
 --header-font: 'Manrope', sans-serif;
 --btn-font: 'Poppins', sans-serif;
}

a{
  -webkit-tap-highlight-color:transparent;
  text-decoration: none;
  color:inherit;
}

html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color:transparent;
}

/* Necessaries and Basics */
body {
  margin: 0;
  background:#fff;
  box-sizing: border-box;
  color: var(--text-black);
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -webkit-tap-highlight-color:transparent;
}
img {
  object-fit:cover;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button, textarea, select {
  font-family: var(--main-font);
  outline: none;
  border: none;
}

input, select{
  font-size: 14px;
  outline:none;
}

button:disabled {
  background-color: #F3F1EF;
  color: #D4CEBF;
  cursor: not-allowed;
  opacity: 0.8;
}

/* Optional: Disabled button with specific styles */
button:disabled.custom-disabled {
  background-color: #f0f0f0;
  border: 1px solid #cccccc;
  color: #D4CEBF;
  cursor: not-allowed;
  opacity: 0.8;
}

@media (max-width:728px){
  input{
    font-size: 16px;
  }
}

input::placeholder{
  color: #B3B9BF;
}

button {
  padding:0.9rem 3rem;
  border-radius:6px;
  font-weight: 500;
}

button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.carousel-gap ul {
  gap:46px !important;
}
.ellipse {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width:728px) {
  .carousel-gap ul {
    gap:0 !important;
  }
}

.navigation-text:hover {
  opacity:0.8;
}

/* General Style */
.main-widget {
  width:100%;
  margin:0 auto;
  padding: 0;
}
.nav-class {
  color:#898579;
  font-weight: 400; 
}

input[type=radio]{
  accent-color: var(--primary-color); 
}

.active-nav {
  color: var(--primary-color);
  font-weight:700;
  background: #FCF9F2;
  border-radius: 10px;
}
.truncate {
  width: 100%; /* Adjust the width as needed */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em; /* Adjust line height based on your design */
  max-height: 3em; /* line-height * number of lines */
}

.suggest-card{
  position: absolute;
  top: 4.5rem;
  left: 0;
  background: #fff;
  z-index: 10;
  width: 100%;
  height: 15rem;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  margin: 0 auto;
  overflow-y: auto;
}
.suggest-wrapper {
  padding: 40px 5%;
  border-bottom: 1px solid #e6e6e6;
  align-items: center;
  cursor: pointer;
}
.suggest-wrapper:hover {
  opacity: 0.9;
}
.suggest-wrapper {
  font-size: 14px;
  color: #70897B;
  padding: 0 20px;
}
/* Indicator */
.indicator-text {
  font-size: 14px;
  position: relative;
  top: -5px;
  color: #70897B;
  padding: 0;
}
/* .active-nav > div {
  border-right: 2px solid var(--primary-color);
} */

.active-bottom-nav > div {
  border-top: 2px solid var(--primary-color);
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
  .main-widget{
    width:100%;
  }
}

/* Pagination  */
.pagination {
  display: flex;
  list-style: none;
  margin: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 3rem;
 
}
.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 2px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: grey;
  font-weight: 500;
  text-decoration: none;
  border:none;
  background: transparent;
}

.page-item a.page-link:hover {
  background-color: #cccccc;
  
}

.page-active .page-link {
  font-weight: 700;
  color: #ffffff;
  background-color: #8B6C23;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}
.nextButton .page-link{
  position: relative;
  display: block;
  margin: 0 2px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 25px;
  text-align: center;
  line-height: 40px;
  color: #05150C;
  font-weight: 500;
  font-weight: 900;
  text-decoration: none;
  background-color: white;
}
.prevButton .page-link span{
  position: relative;
  display: block;
  min-height: 30px;
  min-width: 38px;
  border-radius: 25px;
  text-align: center;
  line-height: 40px;
  color: #05150C;
  font-weight: 900;
  text-decoration: none;
  background-color: white;
  unicode-bidi:bidi-override;
  direction: ltr;
  transform: scale(-1, 1);
}


/* Scroll Bar Override */

/* width */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 5px;
  cursor:pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dbdbdb;
  width: 20px;
  cursor:pointer;
}

@media (max-width:1028px){
  ::-webkit-scrollbar-thumb{
      height:1px;
      display:none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:1px;
    display:none;
    border-radius: 1rem;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:none;
    display:none;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    display:none;
  }
}

/* Checkbox style */

/* .input[type='checkbox'] {
  width: 40px;
  height: 40px;
  border: 2px solid #8B6C23;
  border-radius: 3px;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
} */

/* Checkbox checked state */

input[type=radio], input[type=checkbox]{
  accent-color: #8B6C23; 
}

input[type='checkbox']:checked {
  background-color: #8B6C23;
  border-color: #8B6C23;
}

input[type='checkbox']:checked + input[type='checkbox']::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Optional: Hover state */
input[type='checkbox']:hover {
  border-color: #0056b3;
}

input[type='checkbox']:hover input[type='checkbox']:checked + input[type='checkbox'] {
  background-color: #0056b3;
  border-color: #0056b3;
}